import React from 'react'
import Slider from "react-slick"; 

function Slider_2() {

    var settings = {
        dots: false,
        infinite: true,
        slidesToShow: 5,
        slidesToScroll: 1,
        autoplay: true,
        arrows: false,
        speed: 6000,
        autoplaySpeed: 0,
        cssEase: "linear",
        rtl: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4,
                }
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 3,

                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 2.5,

                }
            },
            {
                breakpoint: 538,
                settings: {
                    slidesToShow: 1.6,
                }
            }
        ]

    }

  return (
    <>

      <div className='slider-icon-padiing'>
                <Slider {...settings}>
                    <div >
                        <div className='Slider-i-div '>
                            <div>
                                <img src="https://global-uploads.webflow.com/627a1044a798e6627445c8d1/627a1045a798e65bb245c969_card-28.svg" alt="" className='slider-icon' />
                            </div>
                            <div>
                                <p className='slide-icon-text'> Product Descriptions</p>
                            </div>
                        </div>
                    </div>
                    <div >
                        <div className='Slider-i-div '>
                            <div>
                                <img src="https://global-uploads.webflow.com/627a1044a798e6627445c8d1/627a1045a798e62f5045c968_card-30.svg" alt="" className='slider-icon' />
                            </div>
                            <div>
                                <p className='slide-icon-text'> Facebook Ads</p>
                            </div>
                        </div>
                    </div>
                    <div >
                        <div className='Slider-i-div '>
                            <div>
                                <img src="https://global-uploads.webflow.com/627a1044a798e6627445c8d1/627a1045a798e61a4845ca2d_card-04.svg" alt="" className='slider-icon' />
                            </div>
                            <div>
                                <p className='slide-icon-text'> Google Ads</p>
                            </div>
                        </div>
                    </div>
                    <div >
                        <div className='Slider-i-div '>
                            <div>
                                <img src="https://global-uploads.webflow.com/627a1044a798e6627445c8d1/627a1045a798e6e36845ca2e_card-02.svg" alt="" className='slider-icon' />
                            </div>
                            <div>
                                <p className='slide-icon-text'> LinkedIn Ads</p>
                            </div>
                        </div>
                    </div>
                    <div >
                        <div className='Slider-i-div '>
                            <div>
                                <img src="https://global-uploads.webflow.com/627a1044a798e6627445c8d1/627a1045a798e63f7545ca2f_card-07.svg" alt="" className='slider-icon' />
                            </div>
                            <div>
                                <p className='slide-icon-text'> YouTube Titles</p>
                            </div>
                        </div>
                    </div>
                    <div >
                        <div className='Slider-i-div '>
                            <div>
                                <img src="https://global-uploads.webflow.com/627a1044a798e6627445c8d1/627a1045a798e63f7545ca2f_card-07.svg" alt="" className='slider-icon' />
                            </div>
                            <div>
                                <p className='slide-icon-text'> YouTube Descriptions</p>
                            </div>
                        </div>
                    </div>
                    <div >
                        <div className='Slider-i-div '>
                            <div>
                                <img src="https://global-uploads.webflow.com/627a1044a798e6627445c8d1/627a1045a798e62a3845ca32_card-10.svg" alt="" className='slider-icon' />
                            </div>
                            <div>
                                <p className='slide-icon-text'> Landing Pages</p>
                            </div>
                        </div>
                    </div>
                    <div >
                        <div className='Slider-i-div '>
                            <div>
                                <img src="https://global-uploads.webflow.com/627a1044a798e6627445c8d1/627a1045a798e6027545c967_card-26.svg" alt="" className='slider-icon' />
                            </div>
                            <div>
                                <p className='slide-icon-text'> Quora Answers</p>
                            </div>
                        </div>
                    </div>
                </Slider>
            </div>
    </>
  )
}

export default Slider_2
