import React, { useState } from 'react'

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Link, useNavigate } from 'react-router-dom';
import { CgMenuRightAlt } from 'react-icons/cg';
import { CgClose } from "react-icons/cg";
import Logo from "../../Img/logo.png"
import NavDropdown from 'react-bootstrap/NavDropdown';


function Header() {

  const [togglebtn, setTogglebtn] = useState(true)
  const navigate = useNavigate()



  const [menucard, serMenucard] = useState(false)




  return (
    <>

      <Navbar expand="lg" className="header-bg"  >
        <Container >
          <Navbar.Brand >
            <Link to={"/"} className='link-underline'>
              <div className='logo-div'>
                <img src={Logo} alt="" className='logo-img' />
                <p className='logo-text'>Brainwave.ai</p>
              </div>
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav"  >

            {togglebtn ? <CgMenuRightAlt className="toogle-btn" onClick={() => setTogglebtn(!true)} /> : <CgClose className="toogle-btn" onClick={() => setTogglebtn(!false)} />}
          </Navbar.Toggle>
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto">
              <Link to={"/download"} className='header-menu'>Download</Link>
            

              {/* menu card */}



              <NavDropdown
                id="nav-dropdown-dark-example"
                title="Features"
                menuVariant="dark"
                className='header-menu'
              >

                <div className='card-menu-card'  >


                  <div className='row'>

                    <div className='col-xl-6 col-lg-6 col-md-6 col-12'>
                      <div className='card-menu-flex-div'>
                        <div className='card-menu-img-div'>
                          <img src="https://global-uploads.webflow.com/627a1044a798e6627445c8d1/627a1045a798e6445845ca46_m-icon-1.svg" alt="" className='card-menu-img' />
                        </div>
                        <div className=' '>
                          <p className='card-menu-head'>AI Article & Blog Writer</p>
                          <p className='card-menu-dis'>Write 1500+ word articles and blogs</p>
                        </div>
                        <div className='ms-auto'>
                          <img src="https://global-uploads.webflow.com/627a1044a798e6627445c8d1/627a1045a798e67dcf45c99e_icon-menu.svg" alt="" className='card-menu-ar-1' />
                          <img src="https://global-uploads.webflow.com/627a1044a798e6627445c8d1/6291e61a354f091d81b94123_purple-menu.svg" alt="" className='card-menu-ar-2' />
                        </div>
                      </div>


                      <div className='card-menu-flex-div'>
                        <div className='card-menu-img-div'>
                          <img src="https://global-uploads.webflow.com/627a1044a798e6627445c8d1/627a1045a798e61c1c45ca48_m-icon-2.svg" alt="" className='card-menu-img' />
                        </div>
                        <div className=' '>
                          <p className='card-menu-head'>Paraphrasing Tool</p>
                          <p className='card-menu-dis'>Instantly paraphrase your text</p>
                        </div>
                        <div className='ms-auto'>
                          <img src="https://global-uploads.webflow.com/627a1044a798e6627445c8d1/627a1045a798e67dcf45c99e_icon-menu.svg" alt="" className='card-menu-ar-1' />
                          <img src="https://global-uploads.webflow.com/627a1044a798e6627445c8d1/6291e61a354f091d81b94123_purple-menu.svg" alt="" className='card-menu-ar-2' />
                        </div>
                      </div>

                      <div className='card-menu-flex-div'>
                        <div className='card-menu-img-div'>
                          <img src="https://global-uploads.webflow.com/627a1044a798e6627445c8d1/627a1045a798e61c1945ca49_m-icon-3.svg" alt="" className='card-menu-img' />
                        </div>
                        <div className=' '>
                          <p className='card-menu-head'>Text Expender</p>
                          <p className='card-menu-dis'>Expand and lengthen your contant</p>
                        </div>
                        <div className='ms-auto'>
                          <img src="https://global-uploads.webflow.com/627a1044a798e6627445c8d1/627a1045a798e67dcf45c99e_icon-menu.svg" alt="" className='card-menu-ar-1' />
                          <img src="https://global-uploads.webflow.com/627a1044a798e6627445c8d1/6291e61a354f091d81b94123_purple-menu.svg" alt="" className='card-menu-ar-2' />
                        </div>
                      </div>

                      <div className='card-menu-flex-div'>
                        <div className='card-menu-img-div'>
                          <img src="https://global-uploads.webflow.com/627a1044a798e6627445c8d1/627a1045a798e68a3b45ca4a_m-icon-4.svg" alt="" className='card-menu-img' />
                        </div>
                        <div className=' '>
                          <p className='card-menu-head'>Article Summarizer</p>
                          <p className='card-menu-dis'>Summarize any piece of content in 1 click</p>
                        </div>
                        <div className='ms-auto'>
                          <img src="https://global-uploads.webflow.com/627a1044a798e6627445c8d1/6291e61a354f091d81b94123_purple-menu.svg" alt="" className='card-menu-ar-2' />
                        </div>
                      </div>

                      <div className='card-menu-flex-div'>
                        <div className='card-menu-img-div'>
                          <img src="https://global-uploads.webflow.com/627a1044a798e6627445c8d1/627a1045a798e6798445c94e_p-icon-5.svg" alt="" className='card-menu-img' />
                        </div>
                        <div className=''>
                          <p className='card-menu-head'>Product Descriptions</p>
                          <p className='card-menu-dis'>Craft epic descriptions for your products</p>
                        </div>
                        <div className='ms-auto'>
                          <img src="https://global-uploads.webflow.com/627a1044a798e6627445c8d1/6291e61a354f091d81b94123_purple-menu.svg" alt="" className='card-menu-ar-2' />
                        </div>
                      </div>



                    </div>
                    <div className='col-xl-6 col-lg-6 col-md-6 col-12'>

                      <div className='card-menu-flex-div'>
                        <div className='card-menu-img-div'>
                          <img src="https://global-uploads.webflow.com/627a1044a798e6627445c8d1/627a1045a798e640a645ca4b_m-icon-5.svg" alt="" className='card-menu-img' />
                        </div>
                        <div className=''>
                          <p className='card-menu-head'>Facebook Ads</p>
                          <p className='card-menu-dis'>Create high-performing FB Ads</p>
                        </div>
                        <div className='ms-auto'>
                          <img src="https://global-uploads.webflow.com/627a1044a798e6627445c8d1/627a1045a798e67dcf45c99e_icon-menu.svg" alt="" className='card-menu-ar-1' />
                          <img src="https://global-uploads.webflow.com/627a1044a798e6627445c8d1/6291e61a354f091d81b94123_purple-menu.svg" alt="" className='card-menu-ar-2' />
                        </div>
                      </div>

                      <div className='card-menu-flex-div'>
                        <div className='card-menu-img-div'>
                          <img src="https://global-uploads.webflow.com/627a1044a798e6627445c8d1/627a1045a798e60be445ca4c_m-icon-6.svg" alt="" className='card-menu-img' />
                        </div>
                        <div className=''>
                          <p className='card-menu-head'>Google Ads</p>
                          <p className='card-menu-dis'>Get high-converting Google ad copy</p>
                        </div>
                        <div className='ms-auto'>
                          <img src="https://global-uploads.webflow.com/627a1044a798e6627445c8d1/6291e61a354f091d81b94123_purple-menu.svg" alt="" className='card-menu-ar-2' />
                        </div>
                      </div>

                      <div className='card-menu-flex-div'>
                        <div className='card-menu-img-div'>
                          <img src="https://global-uploads.webflow.com/627a1044a798e6627445c8d1/627a1045a798e638c645ca4d_m-icon-7.svg" alt="" className='card-menu-img' />
                        </div>
                        <div className='pe-4'>
                          <p className='card-menu-head'>Landing Pages</p>
                          <p className='card-menu-dis'>Develop high-converting landing pages </p>
                        </div>
                        <div className='ms-auto' >
                          <img src="https://global-uploads.webflow.com/627a1044a798e6627445c8d1/627a1045a798e67dcf45c99e_icon-menu.svg" alt="" className='card-menu-ar-1' />
                          <img src="https://global-uploads.webflow.com/627a1044a798e6627445c8d1/6291e61a354f091d81b94123_purple-menu.svg" alt="" className='card-menu-ar-2' />
                        </div>
                      </div>

                      <div className='card-menu-flex-div'>
                        <div className='card-menu-img-div'>
                          <img src="https://global-uploads.webflow.com/627a1044a798e6627445c8d1/627a1045a798e68b4c45ca4e_m-icon-8.svg" alt="" className='card-menu-img' />
                        </div>
                        <div className=''>
                          <p className='card-menu-head'>AI Article Ideas</p>
                          <p className='card-menu-dis'>Catchy blog titles at your fingertips</p>
                        </div>
                        <div className='ms-auto'>
                          <img src="https://global-uploads.webflow.com/627a1044a798e6627445c8d1/6291e61a354f091d81b94123_purple-menu.svg" alt="" className='card-menu-ar-2' />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </NavDropdown>


              <NavDropdown
                id="nav-dropdown-dark-example"
                title="Resources"
                menuVariant="dark"
                className='header-menu'
              >

                <div className='drop-menu-div'>


                  <div className='drop-menu-flex-div'>
                    <div>
                      <p className='drop-menu-text'>Blog</p>
                    </div>
                    <div className='ms-auto'>
                      <img src="https://global-uploads.webflow.com/627a1044a798e6627445c8d1/627a1045a798e6f67d45ca4f_m-arrow-right.svg" alt="" className='dp-menu-img' />
                    </div>
                  </div>
                  <div className='drop-menu-flex-div'>
                    <div>
                      <p className='drop-menu-text'>Guides and Tutorials</p>
                    </div>
                    <div className='ms-auto'>
                      <img src="https://global-uploads.webflow.com/627a1044a798e6627445c8d1/627a1045a798e6f67d45ca4f_m-arrow-right.svg" alt="" className='dp-menu-img' />
                    </div>
                  </div>
                  <div className='drop-menu-flex-div'>
                    <div>
                      <p className='drop-menu-text'>API Docs</p>
                    </div>
                    <div className='ms-auto'>
                      <img src="https://global-uploads.webflow.com/627a1044a798e6627445c8d1/627a1045a798e6f67d45ca4f_m-arrow-right.svg" alt="" className='dp-menu-img' />
                    </div>
                  </div>
                  <div className='drop-menu-flex-div'>
                    <div>
                      <p className='drop-menu-text'>Community</p>
                    </div>
                    <div className='ms-auto'>
                      <img src="https://global-uploads.webflow.com/627a1044a798e6627445c8d1/627a1045a798e6f67d45ca4f_m-arrow-right.svg" alt="" className='dp-menu-img' />
                    </div>
                  </div>
                  <div className='drop-menu-flex-div'>
                    <div>
                      <p className='drop-menu-text'>Free Templates</p>
                    </div>
                    <div className='ms-auto'>
                      <img src="https://global-uploads.webflow.com/627a1044a798e6627445c8d1/627a1045a798e6f67d45ca4f_m-arrow-right.svg" alt="" className='dp-menu-img' />
                    </div>
                  </div>


                </div>
              </NavDropdown>

              <Link to={"/pricing"} className='header-menu'>Pricing</Link>
              <Link to={"/contact"} className='header-menu'>Contact</Link>

            </Nav>
            <Nav>
              <button className='login-btn' onClick={() => navigate("/login")} >Sign in</button>
              <button className='signup-btn' onClick={() => navigate("/signup")} >Get Started</button>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar >


    </>
  )
}

export default Header
