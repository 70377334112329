import React from 'react'
import Slider from "react-slick"; 

function Slider_4() {
    var settings = {
        dots: false,
        infinite: true,
        slidesToShow: 5,
        slidesToScroll: 1,
        autoplay: true,
        arrows: false,
        speed: 6000,
        autoplaySpeed: 0,
        cssEase: "linear",
        rtl: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4,
                }
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 3,

                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 2.5,

                }
            },
            {
                breakpoint: 538,
                settings: {
                    slidesToShow: 1.6,
                }
            }
        ]

    }

    return (
        <>

            <div className='slider-icon-padiing'>
                <Slider {...settings}>
                    <div >
                        <div className='Slider-i-div '>
                            <div>
                                <img src="https://global-uploads.webflow.com/627a1044a798e6627445c8d1/627a1045a798e64c5d45c96b_card-32.svg" alt="" className='slider-icon' />
                            </div>
                            <div>
                                <p className='slide-icon-text'>Feature to Benefit</p>
                            </div>
                        </div>
                    </div>
                    <div >
                        <div className='Slider-i-div '>
                            <div>
                                <img src="https://global-uploads.webflow.com/627a1044a798e6627445c8d1/627a1045a798e627fc45c96c_card-33.svg" alt="" className='slider-icon' />
                            </div>
                            <div>
                                <p className='slide-icon-text'> Pain-Agitate-Solutions</p>
                            </div>
                        </div>
                    </div>
                    <div >
                        <div className='Slider-i-div '>
                            <div>
                                <img src="https://global-uploads.webflow.com/627a1044a798e6627445c8d1/627a1045a798e6cfbb45ca38_card-16.svg" alt="" className='slider-icon' />
                            </div>
                            <div>
                                <p className='slide-icon-text'> AIDA Framework</p>
                            </div>
                        </div>
                    </div>
                    <div >
                        <div className='Slider-i-div '>
                            <div>
                                <img src="https://global-uploads.webflow.com/627a1044a798e6627445c8d1/627a1045a798e6658f45ca3e_card-23.svg" alt="" className='slider-icon' />
                            </div>
                            <div>
                                <p className='slide-icon-text'> Company Bios</p>
                            </div>
                        </div>
                    </div>
                    <div >
                        <div className='Slider-i-div '>
                            <div>
                                <img src="https://global-uploads.webflow.com/627a1044a798e6627445c8d1/627a1045a798e61ca245c96f_card-36.svg" alt="" className='slider-icon' />
                            </div>
                            <div>
                                <p className='slide-icon-text'> Growth ideas</p>
                            </div>
                        </div>
                    </div>
                    <div >
                        <div className='Slider-i-div '>
                            <div>
                                <img src="https://global-uploads.webflow.com/627a1044a798e6627445c8d1/627a1045a798e6658f45ca3e_card-23.svg" alt="" className='slider-icon' />
                            </div>
                            <div>
                                <p className='slide-icon-text'> Company Bios</p>
                            </div>
                        </div>
                    </div>

                    
                </Slider>
            </div>
        </>
    )
}

export default Slider_4
