import React from 'react'
import Logo from "../../Img/logo.png"
import Google_logo from "../../Img/google-logo.png"
import Apple_logo from "../../Img/apple-logo.png"
import { Link } from 'react-router-dom'
function Login() {
    return (
        <>

            <div className='login_container'>

                <div className='login-logo-div'>
                    <div>
                        <img src={Logo} alt="" className='login_logo' />
                    </div>
                    <div>
                        <p className='login_logo_text'>Brainwave.ai</p>
                    </div>
                </div>

                <p className='wb-text'>Welcome back</p>
                <p className='wb-dis'>Have we met before?</p>

                <div className='authorization-div'>
                    <div className='auth-div'>
                        <img src={Google_logo} alt="" className='google-logo-img' />
                        <button className='google-authorization'>  Sign in with Google</button>
                    </div>
                    <div className='auth-div'>
                        <img src={Apple_logo} alt="" className='apple-logo-img' />
                        <button className='google-authorization'>  Sign in with Apple</button>
                    </div>
                </div>

                <p className='on-border-text'>Or continue with email</p>



                <div className='auth-with-email-div'>

                    <p className='businessemail-text'>Business Email</p>
                    <input type="text" name="" id="" className='bussinees-mail-ipt' placeholder='user@example.com' />

                    <button className='signin-op-1'>Sign in with OTP</button>
                    <button className='signin-op-2'>Sign in with password</button>
                    <button className='signin-op-2'>Sign in with SSO</button>


                </div>

                <p className='lost-pass'>Lost your password?</p>
                <p className='lost-pass'>New to Writesonic? <Link to={"/signup"} className='link-signup'>Sign Up</Link></p>
            </div>

        </>
    )
}

export default Login
