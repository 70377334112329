import React from 'react'
import Slider from "react-slick"; 

function Slider_3() {
    var settings = {
        dots: false,
        infinite: true,
        slidesToShow: 5,
        slidesToScroll: 1,
        autoplay: true,
        arrows: false,
        speed: 6000,
        autoplaySpeed: 0,
        cssEase: "linear",
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4,
                }
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 3,

                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 2.5,

                }
            },
            {
                breakpoint: 538,
                settings: {
                    slidesToShow: 1.6,
                }
            }
        ]

    }

    return (
        <>

            <div className='slider-icon-padiing'>
                <Slider {...settings}>
                    <div >
                        <div className='Slider-i-div '>
                            <div>
                                <img src="https://global-uploads.webflow.com/627a1044a798e6627445c8d1/627a1045a798e609af45ca37_card-15.svg" alt="" className='slider-icon' />
                            </div>
                            <div>
                                <p className='slide-icon-text'>Real estate listing descriptions</p>
                            </div>
                        </div>
                    </div>
                    <div >
                        <div className='Slider-i-div '>
                            <div>
                                <img src="https://global-uploads.webflow.com/627a1044a798e6627445c8d1/627a1045a798e6a2dc45ca34_card-12.svg" alt="" className='slider-icon' />
                            </div>
                            <div>
                                <p className='slide-icon-text'> Emails</p>
                            </div>
                        </div>
                    </div>
                    <div >
                        <div className='Slider-i-div '>
                            <div>
                                <img src="https://global-uploads.webflow.com/627a1044a798e6627445c8d1/627a1045a798e6aa0c45ca31_card-09.svg" alt="" className='slider-icon' />
                            </div>
                            <div>
                                <p className='slide-icon-text'> Text Summary</p>
                            </div>
                        </div>
                    </div>
                    <div >
                        <div className='Slider-i-div '>
                            <div>
                                <img src="https://global-uploads.webflow.com/627a1044a798e6627445c8d1/627a1045a798e6207745ca36_card-14.svg" alt="" className='slider-icon' />
                            </div>
                            <div>
                                <p className='slide-icon-text'> Keyword Extractor</p>
                            </div>
                        </div>
                    </div>
                    <div >
                        <div className='Slider-i-div '>
                            <div>
                                <img src="https://global-uploads.webflow.com/627a1044a798e6627445c8d1/627a1045a798e67b8245c96a_card-31.svg" alt="" className='slider-icon' />
                            </div>
                            <div>
                                <p className='slide-icon-text'> Company Mission</p>
                            </div>
                        </div>
                    </div>
                    <div >
                        <div className='Slider-i-div '>
                            <div>
                                <img src="https://global-uploads.webflow.com/627a1044a798e6627445c8d1/627a1045a798e6572745ca3a_card-18.svg" alt="" className='slider-icon' />
                            </div>
                            <div>
                                <p className='slide-icon-text'> Review Responder</p>
                            </div>
                        </div>
                    </div>
                    
                </Slider>
            </div>
        </>
    )
}

export default Slider_3
