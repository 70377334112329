import React from 'react'
import Logo from "../../Img/logo.png"
import Google_logo from "../../Img/google-logo.png"
import Apple_logo from "../../Img/apple-logo.png"
import { Link } from 'react-router-dom'

function Signup() {
    return (
        <>

            <div className='container-fluid'>
                <div className='row'>

                    <div className='col-xl-3 col-lg-4  '>
                        <div className='signup_container'>

                            <div className='signup-logo-div'>
                                <div>
                                    <img src={Logo} alt="" className='login_logo' />
                                </div>
                                <div>
                                    <p className='login_logo_text'>Brainwave.ai</p>
                                </div>
                            </div>

                            <p className='get-start-text'>Get started with 10,000 free words</p>
                            <p className='wb-dis'>No credit card required!</p>

                            <div className='authorization-div'>
                                <div className='auth-div'>
                                    <img src={Google_logo} alt="" className='google-logo-img' />
                                    <button className='google-authorization'>  Sign in with Google</button>
                                </div>
                                <div className='auth-div'>
                                    <img src={Apple_logo} alt="" className='apple-logo-img' />
                                    <button className='google-authorization'>  Sign in with Apple</button>
                                </div>
                            </div>

                            <p className='on-border-text'>Or continue with </p>



                            <div className='auth-with-email-div'>

                                <p className='businessemail-text'>Full name</p>
                                <input type="text" name="" id="" className='bussinees-mail-ipt' placeholder='Enter your full name here' />
                                <p className='businessemail-text'>Business Email</p>
                                <input type="text" name="" id="" className='bussinees-mail-ipt' placeholder='user@example.com' />

                                <button className='signin-op-1'>Sign in with OTP</button>
                                <button className='signin-op-2'>Sign in with password</button>
                                <button className='signin-op-2'>Sign in with SSO</button>


                            </div>

                            <p className='you-have-alredy-acc'>Already have an account? <Link to={"/login"} className='link-signup'>Sign In</Link></p>
                            <p className='policy-dis'>By creating an account, you are agreeing to our <span className='policy'>Terms of Service</span> and <span className='policy'>Privacy Policy.</span> You also agree to receive product-related marketing emails from Writesonic, which you can unsubscribe from at any time.</p>
                        </div>

                    </div>
                    <div className='col-xl-9 col-lg-8  d-xl-block d-lg-block  d-none p-lg-0 p-md-0'>
                        <div className='signup-bgimg-div' >
                    
                           

                            <div className='companay-div'>
                                <p className='freelancer'>Trusted by 1,000,000+ marketing teams, agencies and freelancers</p>
                                <div className='company-img-div mx-auto'>

                                    <img src="https://app.writesonic.com/images/vodafone.svg" alt="" className='company-img' />
                                    <img src="https://app.writesonic.com/images/rakuten.svg" alt="" className='company-img' />
                                    <img src="https://app.writesonic.com/images/spotify.svg" alt="" className='company-img' />
                                    <img src="https://app.writesonic.com/images/moodle.svg" alt="" className='company-img' />
                                    <img src="https://app.writesonic.com/images/meesho.svg " alt="" className='company-img' />



                                    <img src="https://app.writesonic.com/images/schneider.svg " alt="" className='company-img' />
                                    <img src="https://app.writesonic.com/images/nyu.svg " alt="" className='company-img' />
                                    <img src="https://app.writesonic.com/images/genesys.svg " alt="" className='company-img' />
                                    <img src="https://app.writesonic.com/images/harvard.svg " alt="" className='company-img' />
                                    <img src="https://app.writesonic.com/images/marriot.svg " alt="" className='company-img' />


                                </div>
                            </div>
                            
                     


                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Signup
