import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Logo from "../../Img/logo.png"
import { CiLinkedin } from "react-icons/ci"
import { CiFacebook } from "react-icons/ci"
import { CiTwitter } from "react-icons/ci"

function Footer() {

    const navigate = useNavigate()
    return (
        <>

            <div className='container-fluid mode-div-2 pading-div footer-bg-div'>
                <div className='custom-container'>
                    <div className='container'>



                        {/* <div className='footer-pd'>
                            <div className='footer-logo-div'>
                                <div>
                                    <Link to={"/"} className='link-underline'>
                                        <div className='logo-div'>
                                            <img src={Logo} alt="" className='logo-img' />
                                            <p className='logo-text'>Flot.ai</p>
                                        </div>
                                    </Link>
                                </div>
                                <div>
                                    <p className='footer-dis'>  <span className='d-xl-inline d-lg-inline d-md-inline d-none'>|      </span>No.1 AI Copilot for All Apps and Websites</p>
                                </div>
                            </div>

                            <div className='row pt-5'>
                                <div className='col-xl-3 col-lg-3 col-md-3 col-6'>
                                    <div>
                                        <p className='footer-menu-title'>Product</p>
                                        <p className='footer-sub-menu ' onClick={()=>navigate("/")}>Homepage</p>
                                        <p className='footer-sub-menu ' onClick={()=>navigate("/pricing")}>Pricing</p>
                                        <p className='footer-sub-menu ' onClick={()=>navigate("/download")}>Download</p>
                                    </div>
                                </div>
                                <div className='col-xl-3 col-lg-3 col-md-3 col-6'>
                                    <div>
                                        <p className='footer-menu-title'>Contact</p>
                                        <p className='footer-sub-menu '>Customer Support</p>
                                        <p className='footer-sub-menu '>Media Collaboration</p>
                                        <p className='footer-sub-menu '>Media Kit</p>
                                    </div>
                                </div>
                                <div className='col-xl-3 col-lg-3 col-md-3'></div>
                                <div className='col-xl-3 col-lg-3 col-md-3'></div>
                            </div>
                        </div>
                        <p className='footer-copy-right'>©2023 Cubo World Inc. All rights reserved. Terms of Service | Privacy Policy | Do Not Sell My Personal Information | GDPR</p> */}


                        <div>

                            <div className='row padding-footer-row'>
                                <div className='col-xl-6 col-lg-6 col-md-12  '>


                                    <div className='logo-md'>
                                        <Link to={"/"} className='link-underline'>
                                            <div className='logo-div'>
                                                <img src={Logo} alt="" className='logo-img' />
                                                <p className='logo-text'>Brainwave.ai</p>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                                <div className='col-xl-2 col-lg-2 col-md-12 px-0'>
                                    <p className='footer-mini-head'>WHY BRAINWAVE.AI</p>
                                    <p className='footer-mini-dis-1'>Brainwave for Business</p>
                                    <p className='footer-mini-dis-1'>Brainwave for Education</p>
                                    <p className='footer-mini-dis-1'>Brainwave for Individuals</p>
                                    <p className='footer-mini-dis-1'>Brainwave for Transcription</p>
                                    <p className='footer-mini-dis-1'>BrainwavePilot™</p>
                                    <p className='footer-mini-dis-1'>Brainwave Ai Chat</p>
                                    <p className='footer-mini-dis-1'>Pricing</p>
                                </div>
                                <div className='col-xl-2 col-lg-2 col-md-12 ps-xl-5  ps-lg-4'>
                                    <p className='footer-mini-head'>DOWNLOAD</p>
                                    <p className='footer-mini-dis-2'>All Apps</p>
                                    <p className='footer-mini-dis-2'>for iOS</p>
                                    <p className='footer-mini-dis-2'>for Android</p>
                                    <p className='footer-mini-dis-2'>Chrome Extension</p>
                                    <p className='footer-mini-dis-2'>for Slack</p>
                                </div>
                                <div className='col-xl-2 col-lg-2 col-md-12'>
                                    <p className='footer-mini-head'>RESOURCES</p>
                                    <p className='footer-mini-dis-3'>Blog</p>
                                    <p className='footer-mini-dis-3'>Careers</p>
                                    <p className='footer-mini-dis-3'>Press</p>
                                    <p className='footer-mini-dis-3'>Help & Support</p>
                                    <p className='footer-mini-dis-3'>Media Kit</p>
                                    <p className='footer-mini-dis-3'>Affiliate</p>
                                    <p className='footer-mini-dis-3'>Privacy & Security</p>
                                </div>

                                <div className=' col-md-12 px-0 md-block-div'>
                                    <p className='footer-mini-head'>WHY BRAINWAVE.AI</p>
                                    <div className=' social-md-div'>
                                        <div>
                                            <CiLinkedin className='f2-linkden' />
                                            <CiFacebook className='f2-facebook' />
                                            <CiTwitter className='f2-twitter' />
                                        </div>
                                    </div>
                                </div>

                                <div className='social-media-flex-div'>
                                    <div className='copy-right-flex-div'>

                                        <p className='copy-right-text '>© 2023 BRAINWAVE.AI</p>
                                        <p className='copy-right-text '>TERMS OF SERVICE</p>
                                        <p className='copy-right-text '>PRIVACY POLICY</p>
                                        <p className='copy-right-text '>JP</p>
                                        <p className='copy-right-text '>ZOOM</p>
                                    </div>
                                    <div className='social-xl-div'>
                                        <CiLinkedin className='f2-linkden' />
                                        <CiFacebook className='f2-facebook' />
                                        <CiTwitter className='f2-twitter' />
                                    </div>
                                </div>

                            </div>

                        </div>


                    </div>
                </div>


            </div>

        </>
    )
}

export default Footer
