import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'



function Maybeshow({ children }) {

    const location = useLocation()

    const [showNF, setSHOWNF] = useState(false)


    useEffect(() => {

        if (location.pathname === "/login" || location.pathname === "/signup") {
            setSHOWNF(false)
        } else {
            setSHOWNF(true)
        }

    }, [location])



    return (
        <div>
            {showNF && children}
        </div>
    )
}

export default Maybeshow
