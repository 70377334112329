import React, { useState } from 'react'
import { FiCheck } from "react-icons/fi"
import { IoClose } from "react-icons/io5"
import { BsArrowRight } from "react-icons/bs"
import { RiMagicLine } from "react-icons/ri"
import { SiClarifai } from "react-icons/si"
import { RiSpeakLine } from "react-icons/ri"
import { IoBulbOutline } from "react-icons/io5"
import { LuBoxes } from "react-icons/lu"
import { BsLightningChargeFill } from "react-icons/bs"
import { BsFillChatLeftQuoteFill } from "react-icons/bs"
import { BiSolidLike } from "react-icons/bi"
import { HiOutlineSun } from "react-icons/hi"
import { Link } from 'react-router-dom'
import Accordion from 'react-bootstrap/Accordion';
import { HiOutlineMail } from "react-icons/hi"

const Pricing = () => {

  const [monthly, setMonthly] = useState(true)
  return (
    <>

      <div className='container-fluid pading-div position-pading'>
        <div className='custom-container'>
          <div className='container'>

            <h1 className="downlod-heading">Pricing</h1>
            <p className="download-sub-head">Embrace the perfect plan to craft and elevate your work with Flot.ai.</p>




            <div className="pricing-toggle-div">
              <div className="switch-wrapper">

                <input id="monthly" type="radio" name="switch" defaultChecked />
                <input id="yearly" type="radio" name="switch" />
                <label htmlFor="monthly" className="pricing_toggle" onClick={() => { setMonthly(true) }}>Monthly</label>
                <span className='discount-pricing'>Save 25%+</span>
                <label htmlFor="yearly" className="pricing_toggle" onClick={() => { setMonthly(false) }}>Annually</label>
                <span className="highlighter"></span>
              </div>
            </div>

            <div className='pricing-card-div'>
              <div className='pricing-card'>

                <p className='pricing-category-1'>FREE</p>
                <p className='pricing-category-dis-1'>Enjoy basic access to <br /> our platform at no cost.</p>

                <p className='pricing-pricing'>FREE</p>


                <ul>
                  <li className='included-item'>Total 180 credits per month </li>
                  <div className='pricing-arrow-div'>
                    <div>
                      <FiCheck className='pricing-cheak' />
                    </div>
                    <div>
                      <p className='sub-included-item'>3 AI Copilot queries per day</p>
                    </div>
                  </div>
                  <div className='pricing-arrow-div'>
                    <div className='pricing-arrow-div'>
                      <div>
                        <FiCheck className='pricing-cheak' />
                      </div>
                      <div>
                        <p className='sub-included-item'>3 Desktop chat messages per day</p>
                      </div>
                    </div>
                  </div>

                  <li className='included-item'>3 mobile chat messages per day </li>
                  <li className='included-item'>200+ Language Support </li>
                  <li className='included-item'>Email support </li>
                  <div className='pricing-arrow-div'>
                    <div className='pricing-arrow-div'>
                      <div>
                        <IoClose className='close-cheak' />
                      </div>
                      <div>
                        <p className='included-item'>No access to GPT-4</p>
                      </div>
                    </div>
                  </div>
                </ul>
              </div>
              <div className='pricing-card'>

                <p className='pricing-category-2'>PRO</p>
                <p className='pricing-category-dis-1'>Perfect for everyday users <br /> seeking a boost</p>

                <p className='pricing-pricing'>{monthly ? "$15.99" : "$9.99"}<span className='pricing-time'>/mo</span></p>

                <button className='pricing-card-2-btn'> Start Free Trial <BsArrowRight /></button>
                <ul>
                  <li className='included-item-2'>Total 2,500 credits per month  </li>
                  <div className='pricing-arrow-div'>
                    <div className='pricing-arrow-div'>
                      <div>
                        <FiCheck className='pricing-cheak' />
                      </div>
                      <div>
                        <p className='sub-included-item'>AI Copilot queries</p>
                      </div>
                    </div>
                  </div>

                  <div className='pricing-arrow-div'>
                    <div className='pricing-arrow-div'>
                      <div>
                        <FiCheck className='pricing-cheak' />
                      </div>
                      <div>
                        <p className='sub-included-item'>Desktop chat messages</p>
                      </div>
                    </div>
                  </div>


                  <div className='pricing-arrow-div'>
                    <div className='pricing-arrow-div'>
                      <div>
                        <FiCheck className='pricing-cheak' />
                      </div>
                      <div>
                        <p className='sub-included-item'>Support GPT-4 queries (each GPT-4 query consumes 30 credits)</p>
                      </div>
                    </div>
                  </div>

                  <li className='included-item-2'>1,000+ Templates</li>

                  <div className='pricing-arrow-div'>
                    <div className='pricing-arrow-div'>
                      <div>
                        <FiCheck className='pricing-cheak' />
                      </div>
                      <div>
                        <p className='sub-included-item'>An extensive library that aids writing and editing tasks</p>
                      </div>
                    </div>
                  </div>

                  <li className='included-item-2'>AI copilot on all apps and websites</li>

                  <div className='pricing-arrow-div'>
                    <div className='pricing-arrow-div'>
                      <div>
                        <FiCheck className='pricing-cheak' />
                      </div>
                      <div>
                        <p className='sub-included-item'>Summarize</p>
                      </div>
                    </div>
                  </div>

                  <div className='pricing-arrow-div'>
                    <div className='pricing-arrow-div'>
                      <div>
                        <FiCheck className='pricing-cheak' />
                      </div>
                      <div>
                        <p className='sub-included-item'>Improve writing</p>
                      </div>
                    </div>
                  </div>


                  <div className='pricing-arrow-div'>
                    <div className='pricing-arrow-div'>
                      <div>
                        <FiCheck className='pricing-cheak' />
                      </div>
                      <div>
                        <p className='sub-included-item'>Tone adjustment</p>
                      </div>
                    </div>
                  </div>


                  <div className='pricing-arrow-div'>
                    <div className='pricing-arrow-div'>
                      <div>
                        <FiCheck className='pricing-cheak' />
                      </div>
                      <div>
                        <p className='sub-included-item'>Explain</p>
                      </div>
                    </div>
                  </div>


                  <div className='pricing-arrow-div'>
                    <div className='pricing-arrow-div'>
                      <div>
                        <FiCheck className='pricing-cheak' />
                      </div>
                      <div>
                        <p className='sub-included-item'>Brainstorming</p>
                      </div>
                    </div>
                  </div>


                  <div className='pricing-arrow-div'>
                    <div className='pricing-arrow-div'>
                      <div>
                        <FiCheck className='pricing-cheak' />
                      </div>
                      <div>
                        <p className='sub-included-item'>Translate</p>
                      </div>
                    </div>
                  </div>

                  <div className='pricing-arrow-div'>
                    <div className='pricing-arrow-div'>
                      <div>
                        <FiCheck className='pricing-cheak' />
                      </div>
                      <div>
                        <p className='sub-included-item'>More</p>
                      </div>
                    </div>
                  </div>

                  <li className='included-item-2'>Unlimited mobile APP messages</li>
                  <li className='included-item-2'>More benefits</li>

                  <div className='pricing-arrow-div'>
                    <div className='pricing-arrow-div'>
                      <div>
                        <FiCheck className='pricing-cheak' />
                      </div>
                      <div>
                        <p className='sub-included-item'>Faster response time</p>
                      </div>
                    </div>
                  </div>

                  <div className='pricing-arrow-div'>
                    <div className='pricing-arrow-div'>
                      <div>
                        <FiCheck className='pricing-cheak' />
                      </div>
                      <div>
                        <p className='sub-included-item'>Priority email support</p>
                      </div>
                    </div>
                  </div>

                  <div className='pricing-arrow-div'>
                    <div className='pricing-arrow-div'>
                      <div>
                        <FiCheck className='pricing-cheak' />
                      </div>
                      <div>
                        <p className='sub-included-item'>Early access to beta features (on request)</p>
                      </div>
                    </div>
                  </div>

                </ul>
              </div>
              <div className='pricing-card'>
                <div className='most-popular'>
                  <p className='mb-2'>Most <br />Popular</p>
                </div>
                <p className='pricing-category-3'>PRO+</p>
                <p className='pricing-category-dis-1'>Perfect for everyday users <br /> seeking a boost</p>

                <p className='pricing-pricing'>{monthly ? "$19.99" : "$14.99"}<span className='pricing-time'>/mo</span></p>

                <button className='pricing-card-3-btn'> Start Free Trial <BsArrowRight /></button>
                <ul>
                  <li className='included-item-3'>Total 6,000 credits per month  </li>


                  <div className='pricing-arrow-div'>
                    <div className='pricing-arrow-div'>
                      <div>
                        <FiCheck className='pricing-cheak' />
                      </div>
                      <div>
                        <p className='sub-included-item'>AI Copilot queries</p>
                      </div>
                    </div>
                  </div>

                  <div className='pricing-arrow-div'>
                    <div className='pricing-arrow-div'>
                      <div>
                        <FiCheck className='pricing-cheak' />
                      </div>
                      <div>
                        <p className='sub-included-item'>Desktop chat messages</p>
                      </div>
                    </div>
                  </div>


                  <div className='pricing-arrow-div'>
                    <div className='pricing-arrow-div'>
                      <div>
                        <FiCheck className='pricing-cheak' />
                      </div>
                      <div>
                        <p className='sub-included-item'>Support GPT-4 queries (each GPT-4 query consumes 30 credits)</p>
                      </div>
                    </div>
                  </div>

                  <li className='included-item-3'>1,000+ Templates</li>

                  <div className='pricing-arrow-div'>
                    <div className='pricing-arrow-div'>
                      <div>
                        <FiCheck className='pricing-cheak' />
                      </div>
                      <div>
                        <p className='sub-included-item'>An extensive library that aids writing and editing tasks</p>
                      </div>
                    </div>
                  </div>

                  <li className='included-item-3'>AI copilot on all apps and websites</li>

                  <div className='pricing-arrow-div'>
                    <div className='pricing-arrow-div'>
                      <div>
                        <FiCheck className='pricing-cheak' />
                      </div>
                      <div>
                        <p className='sub-included-item'>Summarize</p>
                      </div>
                    </div>
                  </div>

                  <div className='pricing-arrow-div'>
                    <div className='pricing-arrow-div'>
                      <div>
                        <FiCheck className='pricing-cheak' />
                      </div>
                      <div>
                        <p className='sub-included-item'>Improve writing</p>
                      </div>
                    </div>
                  </div>


                  <div className='pricing-arrow-div'>
                    <div className='pricing-arrow-div'>
                      <div>
                        <FiCheck className='pricing-cheak' />
                      </div>
                      <div>
                        <p className='sub-included-item'>Tone adjustment</p>
                      </div>
                    </div>
                  </div>


                  <div className='pricing-arrow-div'>
                    <div className='pricing-arrow-div'>
                      <div>
                        <FiCheck className='pricing-cheak' />
                      </div>
                      <div>
                        <p className='sub-included-item'>Explain</p>
                      </div>
                    </div>
                  </div>


                  <div className='pricing-arrow-div'>
                    <div className='pricing-arrow-div'>
                      <div>
                        <FiCheck className='pricing-cheak' />
                      </div>
                      <div>
                        <p className='sub-included-item'>Brainstorming</p>
                      </div>
                    </div>
                  </div>


                  <div className='pricing-arrow-div'>
                    <div className='pricing-arrow-div'>
                      <div>
                        <FiCheck className='pricing-cheak' />
                      </div>
                      <div>
                        <p className='sub-included-item'>Translate</p>
                      </div>
                    </div>
                  </div>

                  <div className='pricing-arrow-div'>
                    <div className='pricing-arrow-div'>
                      <div>
                        <FiCheck className='pricing-cheak' />
                      </div>
                      <div>
                        <p className='sub-included-item'>More</p>
                      </div>
                    </div>
                  </div>

                  <li className='included-item-3'>Unlimited mobile APP messages</li>
                  <li className='included-item-3'>More benefits</li>

                  <div className='pricing-arrow-div'>
                    <div className='pricing-arrow-div'>
                      <div>
                        <FiCheck className='pricing-cheak' />
                      </div>
                      <div>
                        <p className='sub-included-item'>Faster response time</p>
                      </div>
                    </div>
                  </div>

                  <div className='pricing-arrow-div'>
                    <div className='pricing-arrow-div'>
                      <div>
                        <FiCheck className='pricing-cheak' />
                      </div>
                      <div>
                        <p className='sub-included-item'>Priority email support</p>
                      </div>
                    </div>
                  </div>

                  <div className='pricing-arrow-div'>
                    <div className='pricing-arrow-div'>
                      <div>
                        <FiCheck className='pricing-cheak' />
                      </div>
                      <div>
                        <p className='sub-included-item'>Early access to beta features (on request)</p>
                      </div>
                    </div>
                  </div>

                </ul>
              </div>
            </div>




            {/* AI Copilot Features */}

            <div>
              <h1 className='ai-features-head'>AI Copilot Features</h1>
            </div>


            <div className='ai-copilot-features-row'>
              <div className='row '>
                <div className='col-xl-2 col-lg-2 '></div>
                <div className='col-xl-4 col-lg-4 col-md-6  col-12'>
                  <div className='ai-copilot-flex-div'>
                    <div><RiMagicLine className='ai-copilot-features-icon' /></div>
                    <div>
                      <p className='ai-copilot-features-icon-heading'>Improvement</p>
                    </div>
                  </div>
                </div>

                <div className='col-xl-5  col-lg-5 col-md-6 col-12'>
                  <p className='ai-copilot-fe-dis'>Enhance the overall writing quality</p>
                  <p className='ai-copilot-fe-dis'>Correct spelling and grammar errors</p>
                  <p className='ai-copilot-fe-dis'>Optimize sentence length</p>
                  <p className='ai-copilot-fe-dis'>Simplify language</p>
                  <p className='ai-copilot-fe-dis'>Choose impactful and appropriate word choices</p>
                </div>
                <div className='col-xl-1  col-lg-1'></div>
              </div>
            </div>

            <div className='ai-copilot-features-row'>
              <div className='row '>
                <div className='col-xl-2  col-lg-2'></div>
                <div className='col-xl-4  col-lg-4 col-md-6 col-12'>
                  <div className='ai-copilot-flex-div'>
                    <div><SiClarifai className='ai-copilot-features-icon' /></div>
                    <div>
                      <p className='ai-copilot-features-icon-heading'>Clarity</p>
                    </div>
                  </div>
                </div>

                <div className='col-xl-5  col-lg-5 col-md-6 col-12'>
                  <p className='ai-copilot-fe-dis'>Rewrite, rephrase, or summarise</p>
                  <p className='ai-copilot-fe-dis'>Translation</p>
                  <p className='ai-copilot-fe-dis'>Explanation of the sentence</p>
                </div>
                <div className='col-xl-1  col-lg-1 '></div>
              </div>
            </div>


            <div className='ai-copilot-features-row'>
              <div className='row '>
                <div className='col-xl-2 col-lg-2'></div>
                <div className='col-xl-4 col-lg-4 col-md-6 col-12'>
                  <div className='ai-copilot-flex-div'>
                    <div><RiSpeakLine className='ai-copilot-features-icon' /></div>
                    <div>
                      <p className='ai-copilot-features-icon-heading'>Tone Adjustment</p>
                    </div>
                  </div>
                </div>

                <div className='col-xl-5 col-lg-5 col-md-6 col-12 '>
                  <p className='ai-copilot-fe-dis'>Professional</p>
                  <p className='ai-copilot-fe-dis'>Casual</p>
                  <p className='ai-copilot-fe-dis'>Straightforward</p>
                  <p className='ai-copilot-fe-dis'>Confident</p>
                  <p className='ai-copilot-fe-dis'>Friendly</p>
                </div>
                <div className='col-xl-1  col-lg-1'></div>
              </div>
            </div>


            <div className='ai-copilot-features-row'>
              <div className='row '>
                <div className='col-xl-2 col-lg-2 '></div>
                <div className='col-xl-4 col-lg-4 col-md-6 col-12'>
                  <div className='ai-copilot-flex-div'>
                    <div><IoBulbOutline className='ai-copilot-features-icon' /></div>
                    <div>
                      <p className='ai-copilot-features-icon-heading'>Expansion</p>
                    </div>
                  </div>
                </div>

                <div className='col-xl-5 col-lg-5  col-md-6 col-12'>
                  <p className='ai-copilot-fe-dis'>Brainstorm ideas for various topics</p>
                  <p className='ai-copilot-fe-dis'>Create a blog post draft</p>
                  <p className='ai-copilot-fe-dis'>Outline a document or presentation</p>
                  <p className='ai-copilot-fe-dis'>Generate a social media post</p>
                  <p className='ai-copilot-fe-dis'>Write a press release</p>
                  <p className='ai-copilot-fe-dis'>Create a creative story</p>
                  <p className='ai-copilot-fe-dis'>Compose an essay</p>
                  <p className='ai-copilot-fe-dis'>Write a poem</p>
                  <p className='ai-copilot-fe-dis'>Create a to-do list</p>
                  <p className='ai-copilot-fe-dis'>Draft a meeting agenda</p>
                  <p className='ai-copilot-fe-dis'>Generate a pros and cons list</p>
                  <p className='ai-copilot-fe-dis'>Write a job description</p>
                  <p className='ai-copilot-fe-dis'>Compose a sales email</p>
                  <p className='ai-copilot-fe-dis'>Write a recruiting email</p>
                </div>
                <div className='col-xl-1 col-lg-1 '></div>
              </div>
            </div>


            <div>

              <div className='row'>
                <div className='col-xl-4 col-lg-4 col-md-6 col-12'>
                  <div className='availability-card'>
                    <div>
                      <LuBoxes className='availability-icon-1' />
                    </div>
                    <p className='availability-heading'>Availability</p>
                    <p className='availability-dis'>Mobile: Android & IOS <br /> Desktop: Windows & MacOS</p>
                  </div>
                </div>


                <div className='col-xl-4 col-lg-4 col-md-6 col-12'>
                  <div className='availability-card'>
                    <div>
                      <BsLightningChargeFill className='availability-icon-2' />
                    </div>
                    <p className='availability-heading'>AI Model </p>
                    <p className='availability-dis'>Latest GPT-4 <br />(with plans to update to GPT-5 <br />
                      on its release)</p>
                  </div>
                </div>


                <div className='col-xl-4 col-lg-4 col-md-6 col-12'>
                  <div className='availability-card'>
                    <div>
                      <BsFillChatLeftQuoteFill className='availability-icon-3' />
                    </div>
                    <p className='availability-heading'>Support</p>
                    <p className='availability-dis'>Email & Chat support available <br />24/7</p>

                  </div>
                </div>

                



                <div className='col-xl-4 col-lg-4 col-md-6 col-12'>
                  <div className='availability-card'>
                    <div>
                      <BiSolidLike className='availability-icon-3' />
                    </div>
                    <p className='availability-heading'>Flexibility</p>
                    <p className='availability-dis'>Cancel, downgrade, or upgrade  <br /> at any time</p>
                  </div>
                </div>


                <div className='col-xl-4 col-lg-4 col-md-6 col-12'>
                  <div className='availability-card'>
                    <div>

                      <span className='availability-icon-5'><del>AD</del></span>

                    </div>
                    <p className='availability-heading'>Transparency </p>
                    <p className='availability-dis'>No extra hidden costs,  <br />no ads</p>
                  </div>
                </div>


                <div className='col-xl-4 col-lg-4 col-md-6 col-12'>
                  <div className='availability-card'>
                    <div>
                      <HiOutlineSun className='availability-icon-1' />

                    </div>
                    <p className='availability-heading'>Innovation</p>
                    <p className='availability-dis'>Constantly updating our app to  <br />include new features</p>

                  </div>
                </div>

              </div>
              <button className='pricing-download'>Download</button>
            </div>


            {/* accordion */}

             <div className='row'>

              <div>
                <h1 className='icons-heading'>Frequently Asked Questions</h1>
              </div>

              <div className=''>
                <Accordion alwaysOpen>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>1. What can I use Flot.ai for?</Accordion.Header>
                    <Accordion.Body>
                      Our ChatGPT-based AI chatbot can be used for many tasks like brainstorming ideas, studying, managing tasks, troubleshooting, exploring coding methodologies, conducting market research, and much more. It's versatile and designed to assist in various domains.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>2. Is the Flot.ai safe to use?</Accordion.Header>
                    <Accordion.Body>
                      Absolutely. We prioritize user safety and data privacy. The chatbot is designed to process information locally whenever possible, and any data sent to servers is anonymized and encrypted.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>3. Can I customize the responses?</Accordion.Header>
                    <Accordion.Body>
                      While you can’t directly customize the AI's responses, you can train it to understand your preferences through repeated interaction better. The Chatbot learns from your conversations and adapts to assist you better.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>4. Do you have a  desktop app?</Accordion.Header>
                    <Accordion.Body>
                      We are excited to announce that a new desktopapp is coming! In addition to the chat function,  the forthcoming app will introduce an innovative AI copilot feature. This feature is designed to boost your productivity by providing AI-powered assistance across various tasks, making your work more efficient than ever. Stay tuned for updates!
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="4">
                    <Accordion.Header>5. How can I report the issues?</Accordion.Header>
                    <Accordion.Body>
                      If you have any feedback or encounter issues, contact our support team through the 'Email Support' option in the app. we appreciate user  feedback and strive to improve our app constantly.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="5">
                    <Accordion.Header>6. How can I cancel my subscription?</Accordion.Header>
                    <Accordion.Body>
                      To end your subscription, kindly navigate to Account Settings on the desktop client and cancel the subscription there.
                      If you subscribed via mobile, you can directly access your account settings in the App Store or Google Play Store. Locate your list of subscriptions and select the "Cancel Subscription" option.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>

              </div>
            </div>



            <div className='question-card-div'>
              <div className='question-card'>
                <p className='qustioncard-title'>Still have questions?</p>
                <p className='qustioncard-dis'>We're here to assist you with any further questions! Please don't hesitate to contact us, and we'll be delighted to help.</p>
                <button className='question-card-button' > <Link to={"mailto:abc@gmail.com"} className='mail-link'><HiOutlineMail className='qustion-card-mail-icon' />Contact us</Link></button>
              </div>
            </div>


          </div>
        </div>
      </div>

    </>
  )
}

export default Pricing
