import React from 'react';
import Slider from "react-slick";



function Slider_1() {


    var settings = {
        dots: false,
        infinite: true,
        slidesToShow: 5,
        slidesToScroll: 1,
        autoplay: true,
        arrows: false,
        speed: 6000,
        autoplaySpeed: 0,
        cssEase: "linear",
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4,
                }
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 3,

                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 2.5,

                }
            },
            {
                breakpoint: 538,
                settings: {
                    slidesToShow: 1.6,
                }
            }
        ]

    }


    return (
        <>

            <div className='slider-icon-padiing'>
                <Slider {...settings}>
                    <div >
                        <div className='Slider-i-div '>
                            <div>
                                <img src="https://global-uploads.webflow.com/627a1044a798e6627445c8d1/627a1045a798e6792645ca33_card-11.svg" alt="" className='slider-icon' />
                            </div>
                            <div>
                                <p className='slide-icon-text'> SEO Meta Tags</p>
                            </div>
                        </div>
                    </div>
                    <div >
                        <div className='Slider-i-div '>
                            <div>
                                <img src="https://global-uploads.webflow.com/627a1044a798e6627445c8d1/627a1045a798e6fe6345ca23_card-03.svg" alt="" className='slider-icon' />
                            </div>
                            <div>
                                <p className='slide-icon-text'> AI Article Ideas</p>
                            </div>
                        </div>
                    </div>
                    <div >
                        <div className='Slider-i-div '>
                            <div>
                                <img src="https://global-uploads.webflow.com/627a1044a798e6627445c8d1/627a1045a798e6018945ca3c_card-20.svg" alt="" className='slider-icon' />
                            </div>
                            <div>
                                <p className='slide-icon-text'> AI Article Intros</p>
                            </div>
                        </div>
                    </div>
                    <div >
                        <div className='Slider-i-div '>
                            <div>
                                <img src="https://global-uploads.webflow.com/627a1044a798e6627445c8d1/627a1045a798e6586545ca2c_card-05.svg" alt="" className='slider-icon' />
                            </div>
                            <div>
                                <p className='slide-icon-text'> AI Article Outlines</p>
                            </div>
                        </div>
                    </div>
                    <div >
                        <div className='Slider-i-div '>
                            <div>
                                <img src="https://global-uploads.webflow.com/627a1044a798e6627445c8d1/627a1045a798e66cd745c95f_card-24.svg" alt="" className='slider-icon' />
                            </div>
                            <div>
                                <p className='slide-icon-text'> AI Article & Blog Writer</p>
                            </div>
                        </div>
                    </div>
                    <div >
                        <div className='Slider-i-div '>
                            <div>
                                <img src="https://global-uploads.webflow.com/627a1044a798e6627445c8d1/627a1045a798e680d245c966_card-25.svg" alt="" className='slider-icon' />
                            </div>
                            <div>
                                <p className='slide-icon-text'> Sonic Editor</p>
                            </div>
                        </div>
                    </div>
                    <div >
                        <div className='Slider-i-div '>
                            <div>
                                <img src="https://global-uploads.webflow.com/627a1044a798e6627445c8d1/627a1045a798e61c1945ca49_m-icon-3.svg" alt="" className='slider-icon' />
                            </div>
                            <div>
                                <p className='slide-icon-text'> Sentence Expander</p>
                            </div>
                        </div>
                    </div>
                    <div >
                        <div className='Slider-i-div '>
                            <div>
                                <img src="https://global-uploads.webflow.com/627a1044a798e6627445c8d1/627a1045a798e637f045ca30_card-08.svg" alt="" className='slider-icon' />
                            </div>
                            <div>
                                <p className='slide-icon-text'> Content Rephrase</p>
                            </div>
                        </div>
                    </div>
                </Slider>
            </div>

        </>
    )
}

export default Slider_1
