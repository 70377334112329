import React from 'react'
import {BsArrowRight} from "react-icons/bs"
import { useNavigate } from 'react-router-dom'

function Contact() {
  


  return (
    <>


      <div className='container-fluid  pading-div position-pading'>
        <div className='custom-container'>
          <div className='container'>


            <div className='contact-row-1'>
              <div className='row'>
                <div className='col-xl-2 col-lg-2'>

                </div>
                <div className='col-xl-5 col-lg-5 col-md-6 col-12'>
                  <div className='contact-row-1'>
                    <p className='contact-heading'>Customer Support</p>
                    <p className='contact-mail'>Contact@flot.ai</p>
                  </div>
                </div>
                <div className='col-xl-3 col-lg-3 col-md-6 col-12'>
                  <div className='contact-row-1'>
                    <p className='contact-heading'>Talk to CEO</p>
                    <p className='contact-mail'>Leo@flot.ai</p>
                  </div>
                </div>
                <div className='col-xl-2 col-lg-2 '></div>


                <div className='col-xl-2 col-lg-2 '>

                </div>
                <div className='col-xl-5 col-lg-5 col-md-6 col-12'>
                  <div className='contact-row-1'>
                    <p className='medical-heading'>Media Collaboration <BsArrowRight className='contact-right-arrow'/></p>
                    <p className='contact-mail'>sunny@flot.ai</p>
                    <button className='contact-trail-button' > Media Kit <BsArrowRight className='button-right-arrow'/></button>
                  </div>
                </div>
                <div className='col-xl-3 col-lg-3 col-md-6 col-12 '>
                  <div className='contact-row-1'>
                    <p className='contact-heading'>Partnership Inquiry</p>
                    <p className='contact-mail'>Kelly@flot.ai</p>
                  </div>
                </div>
                <div className='col-xl-2 col-lg-2'></div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  )
}

export default Contact
