import React from 'react'
import apple_pc from "../../Img/apple-laptop.png"
import windows_pc from "../../Img/windows-laptop.png"
import Download_1 from "../../Img/Download_1.webp"
import Download_2 from "../../Img/Download_2.webp"
import Download_3 from "../../Img/Ai_copilot_5.webp"


function Download() {
  return (
    <>


      <div className='container-fluid mode-div-2 pading-div position-pading'>
        <div className='custom-container'>
          <div className='container'>

            <div>

              <h1 className='downlod-heading'>Install Flot on All Your Devices</h1>
              <p className='download-sub-head'>Flot for Mac & Windows</p>
              <p className='download-counter'>200,000+ Downloads</p>

            </div>

            <div className='downlod-card-div'>
              <div className='download-pakage-card'>
                <img src={apple_pc} alt="" className='pakage_img' />
                <p className='download-fro'> Download for</p>
                <p className='download-systum'>macOS</p>
              </div>
              <div className='download-pakage-card'>
                <img src={windows_pc} alt="" className='pakage_img' />
                <p className='download-fro'> Download for</p>
                <p className='download-systum'>Windows</p>
              </div>
            </div>



            <div >

              {/* row - 1 */}

              <div className='download-row'>
                <div className='row'>
                  <div className='copilot-contact' >

                    <div className='col-xl-5  col-lg-5  col-md-12 '>
                      <img src={Download_1} alt="" className='img-fluid Ai-copilot-img' />
                    </div>

                    <div className='col-xl-5 offset-xl-2 col-lg-5 offset-lg-2 col-md-12'>
                      <p className='Download-heading'>Unparalleled AI Assistance   <br className='d-xl-block d-lg-block d-none' />Anywhere You Need</p>
                      <p className='Download-dis'>An AI Copilot capable of create, compose, proofread and translate anywhere you type and select.</p>
                    </div>
                  </div>
                </div>
              </div>


              {/* row - 2 */}
              <div className='download-row'>
                <div className='row'>
                  <div className='copilot-contact' >

                    <div className='col-xl-5 offset-xl-2 col-lg-5 offset-lg-2 col-md-12 d-md-block d-sm-block d-block d-xl-none d-lg-none'>
                      <img src={Download_2} alt="" className='img-fluid Ai-copilot-img' />
                    </div>

                    <div className='col-xl-5  col-lg-5 col-md-12'>

                      <p className='Download-heading'>A Truly Intelligent AI    <br className='d-xl-block d-lg-block d-none' />Companion for 24/7</p>
                      <p className='Download-dis'>A wise friend you can turn to for any problem, at any time.</p>

                    </div>

                    <div className='col-xl-5 offset-xl-2 col-lg-5 offset-lg-2 col-md-12 d-none d-xl-block d-lg-block '>
                      <img src={Download_2} alt="" className='img-fluid Ai-copilot-img' />
                    </div>
                  </div>
                </div>
              </div>


              {/* row - 3 */}


              <div className='download-row'>
                <div className='row'>
                  <div className='copilot-contact' >

                    <div className='col-xl-5  col-lg-5  col-md-12 '>
                      <img src={Download_3} alt="" className='img-fluid Ai-copilot-img' />
                    </div>

                    <div className='col-xl-5 offset-xl-2 col-lg-5 offset-lg-2 col-md-12'>
                      <p className='Download-heading'>Over 1000 Fine-tuned <br className='d-xl-block d-lg-block d-none' />Prompts to Help You<br className='d-xl-block d-lg-block d-none' />Discover What AI Can <br className='d-xl-block d-lg-block d-none' />Really Do  </p>
                      <p className='Download-dis'>Writing, academic, lifestyle… Use expert prompts to draw the best answers from any questions.</p>
                    </div>
                  </div>
                </div>
              </div>

            </div>


          </div>
        </div>
      </div>

    </>
  )
}

export default Download
