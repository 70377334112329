import React from 'react'
import chatgpt_icon from "../../Img/chatgpt-icon.png"
import Laptop_bottom from "../../Img/laptop_bottom.png"
import mobile from "../../Img/mobile_img.webp"
import { IoBulb } from "react-icons/io5"
import { IoBulbOutline } from "react-icons/io5"
import Ai_copylot_1 from "../../Img/Ai_copilot_1.webp"
import Ai_copylot_2 from "../../Img/Ai_copilot_2.webp"
import { RiSpeakLine } from "react-icons/ri"
import Ai_copylot_3 from "../../Img/Ai_copilot_3.webp"
import Ai_copylot_4 from "../../Img/Ai_copilot_4.webp"
import { RiVipCrown2Line } from "react-icons/ri"
import Ai_copylot_5 from "../../Img/Ai_copilot_5.webp"
import { RiMagicLine } from "react-icons/ri"
import Ai_copylot_6 from "../../Img/Ai_copilot_6.webp"
import { TbWorld } from "react-icons/tb"

// icons imgs

// Social Media

import Linkden from "../../Img/linkedin.png"
import FaceBook from "../../Img/facebook.png"
import Twitter from "../../Img/twitter.png"
import Quara from "../../Img/quora.png"
import Reddit from "../../Img/reddit.png"
import Yoytube from "../../Img/youtube.png"
import behance from "../../Img/behance.png"
import Instagram from "../../Img/instagram.png"
import Tiktok from "../../Img/tik-tok.png"
import discord from "../../Img/discord.png"
import Medium from "../../Img/medium.png"
import pinterest from "../../Img/pinterest.png"
import github from "../../Img/github.png"

//Browsers

import chrome from "../../Img/chrome.png"
import firefox from "../../Img/firefox.png"
import edge from "../../Img/edge.png"
import safari from "../../Img/safari.png"
import bing from "../../Img/bing.png"
import brave from "../../Img/brave.png"
import opera from "../../Img/opera.png"

// Development Tools

import framer from "../../Img/framer.png"
import webflow from "../../Img/webflow.png"
import wordpress from "../../Img/wordpress.png"
import sublime from "../../Img/sublime.png"
import docker from "../../Img/docker.png"
import codepen from "../../Img/codepen.png"
import git from "../../Img/git.png"
import Shopify from "../../Img/Shopify.png"
import wix from "../../Img/wix.png"
import Magento from "../../Img/Magento.png"
import visual_studio from "../../Img/visual_studio.png"

// Office Apps
import oficon_1 from "../../Img/gmail.png"
import oficon_2 from "../../Img/drive.png"
import oficon_3 from "../../Img/calendar.png"
import oficon_4 from "../../Img/docs.png"
import oficon_5 from "../../Img/form_list.png"
import oficon_6 from "../../Img/forms.png"
import oficon_7 from "../../Img/slack.png"
import oficon_8 from "../../Img/excel.png"
import oficon_9 from "../../Img/word.png"
import oficon_10 from "../../Img/onenote.png"
import oficon_11 from "../../Img/outlook.png"
import oficon_12 from "../../Img/power_point.png"
import oficon_13 from "../../Img/teams.png"
import oficon_14 from "../../Img/notion.png"
import oficon_15 from "../../Img/publisher.png"
import oficon_16 from "../../Img/access.png"
import oficon_17 from "../../Img/sharepoint.png"
import oficon_18 from "../../Img/onedrive.png"
import oficon_19 from "../../Img/Mail.png"
import oficon_20 from "../../Img/EasyFind.png"
import oficon_21 from "../../Img/yelp.png"
import oficon_22 from "../../Img/steam.png"


// table 

import chat_table_gpt from "../../Img/table_chat_gpt.png"
import Jasper from "../../Img/jasper.png"
import grammarly from "../../Img/grammarly.png"
import { SiNotion } from "react-icons/si"
import { HiCheck } from "react-icons/hi"

// accordion


import Accordion from 'react-bootstrap/Accordion';


// contact us card 

import { HiOutlineMail } from "react-icons/hi"
import { Link } from 'react-router-dom'
import { LuBoxes } from 'react-icons/lu'
import { BsLightningChargeFill } from 'react-icons/bs'
import { BiSolidLike } from 'react-icons/bi'



//slider icon

import Slider_1 from '../Slider/Slider_1'
import Slider_2 from '../Slider/Slider_2'
import Slider_3 from '../Slider/Slider_3'
import Slider_4 from '../Slider/Slider_4'

//Figma card icon

import fgi_1 from "../../Img/fgi_1.png"
import fgi_2 from "../../Img/fgi_2.png"
import fgi_3 from "../../Img/fgi_3.png"
import fgi_4 from "../../Img/fgi_4.png"
import fgi_5 from "../../Img/fgi_5.png"
import fgi_6 from "../../Img/fgi_6.png"
import fgi_7 from "../../Img/fgi_7.png"
import fgi_8 from "../../Img/fgi_8.png"
import fgi_9 from "../../Img/fgi_9.png"
import fgi_10 from "../../Img/fgi_10.png"

function Home() {


    return (
        <>

            <div className='container-fluid pading-div position-pading'>
                <div className='custom-container'>
                    < div className='container' >


                        <div className='hero-div'>
                            <div className='powerby-div'>

                                <div className='powerby-contant-div'>
                                    <div>
                                        <img src={chatgpt_icon} alt="" className='chatgpt-icon' />
                                    </div>
                                    <div>
                                        <p className='chat-gtp-text'>Powered by ChatGPT + GPT-4</p>
                                    </div>
                                </div>
                            </div>


                            <div>
                                <h1 className='hero-text'>Your <span className='hero-text-span'>AI Copilot</span> for All Apps and Websites</h1>

                                <p className='hero-discrip'>Use ChatGPT anywhere you type or select without copy-pasting. Ask AI to write, improve, paraphrase, summarize, translate, explain, or reply to any text with one click.</p>

                            </div>
                            <button className='hero-free-trial-btn'>Start Free Trial</button>


                        </div>

                        <div className='video-section-margin'>
                            <div className='laptop-mobile-div'>
                                <div>
                                    <div className='laptop-div'>
                                        <video controls muted loop poster="https://framerusercontent.com/images/tKlKWPs5YxwXdAOxANeJ0Qypq54.png" className='home-video' >
                                            <source src="https://framerusercontent.com/modules/assets/UVNYPlgdvDXRiAnSP9ewY37Yg~nItWr2uwL0CkoZTnuYjk6V7NTHz5laNDPP5KjHoFxas.mp4" type="" />
                                        </video>
                                    </div>
                                    <img src={Laptop_bottom} alt="" className='laptiop-bootom-div' />
                                </div>
                                <div>
                                    <img src={mobile} alt="" className='mobile-img' />
                                </div>
                            </div>
                        </div>



                        <div className='row'>
                            <div className='col-xl-4 col-lg-4'>
                                <div className='service-card'>
                                    <div>
                                        <BiSolidLike className='availability-icon-3' />
                                    </div>

                                    <p className='service-titel'>Flexibility</p>
                                    <p className='service-dis'>Functions universally,<br />anywhere you type or select</p>
                                </div>
                            </div>
                            <div className='col-xl-4 col-lg-4'>
                                <div className='service-card'>
                                    <div>

                                        <BsLightningChargeFill className='availability-icon-2' />
                                    </div>

                                    <p className='service-titel'>Innovation</p>
                                    <p className='service-dis'>Latest Artificial Intelligence<br /> Model by OpenAI: GPT-4</p>
                                </div>
                            </div>
                            <div className='col-xl-4 col-lg-4'>
                                <div className='service-card'>
                                    <div>
                                        <LuBoxes className='availability-icon-1' />
                                    </div>

                                    <p className='service-titel'>Availability</p>
                                    <p className='service-dis'>Available for Windows,<br />macOS, iOS and Android</p>
                                </div>
                            </div>

                        </div>



                        <div className='ai-copilot-div'>
                            <h1 className='ai-copilot-text'>Your Ultimate <span className='hero-text-span'>AI Copilot</span> Always by Your Side: <br />
                                Right On Any App or Website</h1>
                        </div>


                        {/* row - 1  */}
                        <div className='row'>
                            <div className='copilot-contact' >

                                <div className='col-xl-5 offset-xl-2 col-lg-5 offset-lg-2 col-md-12 d-md-block d-sm-block d-block d-xl-none d-lg-none'>
                                    <img src={Ai_copylot_1} alt="" className='img-fluid' />
                                </div>

                                <div className='col-xl-5  col-lg-5 col-md-12'>

                                    <div className='copilot-icon-bg'>
                                        <IoBulb className='copilot-icon' />
                                    </div>

                                    <p className='copilot-heading'>AI Copilot that Fits Right into <br className='d-xl-block d-lg-block d-none' />Your Workflow</p>
                                    <p className='copilot-dis'>Flot unmatched support across apps, browsers, email clients, docs, tools, etc. It's like Notion AI, but with the flexibility to use it anywhere on your computer.</p>
                                    <button className='copilot-btn'>Start Free Trial</button>
                                </div>

                                <div className='col-xl-5 offset-xl-2 col-lg-5 offset-lg-2 col-md-12 d-none d-xl-block d-lg-block '>
                                    <img src={Ai_copylot_1} alt="" className='img-fluid Ai-copilot-img' />
                                </div>
                            </div>
                        </div>

                        {/* row - 2 */}


                        <div className='row'>
                            <div className='copilot-contact' >

                                <div className='col-xl-5  col-lg-5  col-md-12 '>
                                    <img src={Ai_copylot_2} alt="" className='img-fluid Ai-copilot-img' />
                                </div>

                                <div className='col-xl-5 offset-xl-2 col-lg-5 offset-lg-2 col-md-12'>

                                    <div className='copilot-icon-bg'>
                                        <IoBulbOutline className='copilot-icon' />
                                    </div>

                                    <p className='copilot-heading'>Crafting & Ideating <br className='d-xl-block d-lg-block d-none' />Outlines with Ease</p>
                                    <p className='copilot-dis'>Enhance your creative process with a brainstorming partner that provides innovative ideas and solutions</p>
                                    <button className='copilot-btn'>Start Free Trial</button>
                                </div>


                            </div>
                        </div>

                        {/* row - 3 */}


                        <div className='row'>
                            <div className='copilot-contact' >

                                <div className='col-xl-5 offset-xl-2 col-lg-5 offset-lg-2 col-md-12 d-md-block d-sm-block d-block d-xl-none d-lg-none'>
                                    <img src={Ai_copylot_3} alt="" className='img-fluid Ai-copilot-img' />
                                </div>

                                <div className='col-xl-5  col-lg-5 col-md-12'>

                                    <div className='copilot-icon-bg'>
                                        <RiSpeakLine className='copilot-icon' />
                                    </div>

                                    <p className='copilot-heading'>Grammar Corrections  <br className='d-xl-block d-lg-block d-none' />and Polished Tones</p>
                                    <p className='copilot-dis'>Our AI app for PC is a grammar guru, capable of identifying errors and polishing your work to perfection.</p>
                                    <button className='copilot-btn'>Start Free Trial</button>
                                </div>

                                <div className='col-xl-5 offset-xl-2 col-lg-5 offset-lg-2 col-md-12 d-none d-xl-block d-lg-block '>
                                    <img src={Ai_copylot_3} alt="" className='img-fluid Ai-copilot-img' />
                                </div>
                            </div>
                        </div>


                        {/* row - 4 */}


                        <div className='row'>
                            <div className='copilot-contact' >

                                <div className='col-xl-5  col-lg-5  col-md-12 '>
                                    <img src={Ai_copylot_4} alt="" className='img-fluid Ai-copilot-img' />
                                </div>

                                <div className='col-xl-5 offset-xl-2 col-lg-5 offset-lg-2 col-md-12'>

                                    <div className='copilot-icon-bg'>
                                        <RiVipCrown2Line className='copilot-icon' />
                                    </div>

                                    <p className='copilot-heading'>Compose Like a Pro</p>
                                    <p className='copilot-dis'>An AI copilot that can help you write content with its vast reservoir of knowledge and experience.</p>
                                    <button className='copilot-btn'>Start Free Trial</button>
                                </div>


                            </div>
                        </div>

                        {/* row - 5 */}


                        <div className='row'>
                            <div className='copilot-contact' >

                                <div className='col-xl-5 offset-xl-2 col-lg-5 offset-lg-2 col-md-12 d-md-block d-sm-block d-block d-xl-none d-lg-none'>
                                    <img src={Ai_copylot_5} alt="" className='img-fluid Ai-copilot-img' />
                                </div>

                                <div className='col-xl-5  col-lg-5 col-md-12'>

                                    <div className='copilot-icon-bg'>
                                        <RiMagicLine className='copilot-icon' />
                                    </div>

                                    <p className='copilot-heading'>Expert Prompts to Use   <br className='d-xl-block d-lg-block d-none' />Right Away</p>
                                    <p className='copilot-dis'>Access Flot's professionally tested prompts to maximize AI's full potential.</p>
                                    <button className='copilot-btn'>Start Free Trial</button>
                                </div>

                                <div className='col-xl-5 offset-xl-2 col-lg-5 offset-lg-2 col-md-12 d-none d-xl-block d-lg-block '>
                                    <img src={Ai_copylot_5} alt="" className='img-fluid Ai-copilot-img' />
                                </div>
                            </div>
                        </div>


                        {/* row - 6 */}


                        <div className='row'>
                            <div className='copilot-contact' >

                                <div className='col-xl-5  col-lg-5  col-md-12 '>
                                    <img src={Ai_copylot_6} alt="" className='img-fluid Ai-copilot-img' />
                                </div>

                                <div className='col-xl-5 offset-xl-2 col-lg-5 offset-lg-2 col-md-12'>

                                    <div className='copilot-icon-bg'>
                                        <TbWorld className='copilot-icon' />
                                    </div>

                                    <p className='copilot-heading'>Multilingual Capabilities:  <br className='d-xl-block d-lg-block d-none' />Connect With the World</p>
                                    <p className='copilot-dis'>Support Over 200 Languages Ask in Your Mother Tongue Localized Answers</p>
                                    <button className='copilot-btn'>Start Free Trial</button>
                                </div>


                            </div>
                        </div>


                        {/* icon - slider  */}

                        <div className='icon-slider-div'>

                            <div>

                                <p className='iconslider-heading'>What can Writesonic write for you?</p>
                                <p className='iconslider-dis'>Our AI has been trained on high-performing copy from the top brands. It knows <br className='d-xl-inline d-lg-inline d-md-imline d-none' /> what converts and how to write content that resonates with your audience.</p>

                            </div>

                            <div className='icon-gradiant-div'>
                                <Slider_1 />
                                <Slider_2 />
                                <Slider_3 />
                                <Slider_4 />

                                <div className='icon-slider-bottom-div'>
                                    <p className='icon-slider-bootom-head'>Over 100 powerful features</p>
                                    <p className='icon-slider-bootom-dis'>Generate high-quality articles, blogs, landing pages, Google ads,<br className='d-xl-inline d-lg-inline d-md-imline  d-sm-imline d-none' /> Facebook ads, emails, product descriptions, tweets, Instagram captions, and more in seconds.</p>
                                    <button className='icon-slider-button'>Get Started for Free</button>
                                </div>
                            </div>


                        </div>





                        {/*  apps icons */}

                        <div className='row'>

                            <div>
                                <h1 className='icons-heading'>Works Perfectly with All Docs, Apps, and Websites.</h1>
                            </div>

                            <div className='col-xl-8 col-lg-8'>
                                <div className='icons_bg-1'>
                                    <p className='socil-media-title'>Social Media</p>
                                    <img src={Linkden} alt="" className='icon_social ' />
                                    <img src={FaceBook} alt="" className='icon_social' />
                                    <img src={Twitter} alt="" className='icon_social' />
                                    <img src={Quara} alt="" className='icon_social' />
                                    <img src={Reddit} alt="" className='icon_social' />
                                    <img src={Yoytube} alt="" className='icon_social' />
                                    <img src={behance} alt="" className='icon_social' />
                                    <img src={Instagram} alt="" className='icon_social' />
                                    <img src={Tiktok} alt="" className='icon_social' />
                                    <img src={discord} alt="" className='icon_social' />
                                    <img src={Medium} alt="" className='icon_social' />
                                    <img src={pinterest} alt="" className='icon_social' />
                                    <img src={github} alt="" className='icon_social' />
                                </div>
                            </div>
                            <div className='col-xl-4 col-lg-4'>
                                <div className='icons_bg-2'>
                                    <p className='socil-media-title'>Browsers</p>
                                    <img src={chrome} alt="" className='icon_social ' />
                                    <img src={firefox} alt="" className='icon_social' />
                                    <img src={edge} alt="" className='icon_social' />
                                    <img src={safari} alt="" className='icon_social' />
                                    <img src={bing} alt="" className='icon_social' />
                                    <img src={brave} alt="" className='icon_social' />
                                    <img src={opera} alt="" className='icon_social' />

                                </div>
                            </div>

                            <div className='col-xl-4 col-lg-4'>
                                <div className='icons_bg-3'>
                                    <p className='socil-media-title'>Development Tools</p>
                                    <img src={framer} alt="" className='icon_social ' />
                                    <img src={webflow} alt="" className='icon_social' />
                                    <img src={wordpress} alt="" className='icon_social' />
                                    <img src={sublime} alt="" className='icon_social' />
                                    <img src={docker} alt="" className='icon_social' />
                                    <img src={codepen} alt="" className='icon_social' />
                                    <img src={git} alt="" className='icon_social' />
                                    <img src={Shopify} alt="" className='icon_social' />
                                    <img src={wix} alt="" className='icon_social' />
                                    <img src={Magento} alt="" className='icon_social' />
                                    <img src={visual_studio} alt="" className='icon_social' />

                                </div>
                            </div>


                            <div className='col-xl-8 col-lg-8'>
                                <div className='icons_bg-4'>
                                    <p className='socil-media-title'>Office Apps</p>
                                    <img src={oficon_1} alt="" className='icon_social ' />
                                    <img src={oficon_2} alt="" className='icon_social ' />
                                    <img src={oficon_3} alt="" className='icon_social ' />
                                    <img src={oficon_4} alt="" className='icon_social ' />
                                    <img src={oficon_5} alt="" className='icon_social ' />
                                    <img src={oficon_6} alt="" className='icon_social ' />
                                    <img src={oficon_7} alt="" className='icon_social ' />
                                    <img src={oficon_8} alt="" className='icon_social ' />
                                    <img src={oficon_9} alt="" className='icon_social ' />
                                    <img src={oficon_10} alt="" className='icon_social ' />
                                    <img src={oficon_11} alt="" className='icon_social ' />
                                    <img src={oficon_12} alt="" className='icon_social ' />
                                    <img src={oficon_13} alt="" className='icon_social ' />
                                    <img src={oficon_14} alt="" className='icon_social ' />
                                    <img src={oficon_15} alt="" className='icon_social ' />
                                    <img src={oficon_16} alt="" className='icon_social ' />
                                    <img src={oficon_17} alt="" className='icon_social ' />
                                    <img src={oficon_18} alt="" className='icon_social ' />
                                    <img src={oficon_19} alt="" className='icon_social ' />
                                    <img src={oficon_20} alt="" className='icon_social ' />
                                    <img src={oficon_21} alt="" className='icon_social ' />
                                    <img src={oficon_22} alt="" className='icon_social ' />

                                </div>
                            </div>
                        </div>


                        {/* Features */}



                        <div className='row'>

                            <div>
                                <h1 className='icons-heading'>Less Cost, More Features</h1>
                            </div>



                            <div className='table-div'>
                                <table>
                                    <thead>
                                        <tr className='tb-border'>
                                            <th className='featurs'>Features</th>
                                            <th className='featurs-sub'>Monthly Cost</th>
                                            <th className='featurs-sub'>AI Copilot</th>
                                            <th className='featurs-sub'>AI Chatbots</th>
                                            <th className='featurs-sub'>GPT-4 Access</th>
                                            <th className='featurs-sub'>Grammar correction</th>
                                            <th className='featurs-sub'>Compose draft & outlines</th>
                                            <th className='featurs-sub'>Expert prompts</th>
                                            <th className='featurs-sub'>Desktop app</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        <tr className='tb-border'>
                                            <td className='table-icon-heading-pro'>
                                                <IoBulbOutline className='table-bulb-pro' />
                                                Flot PRO</td>
                                            <td className='table-text-data-pro'> $9.99</td>
                                            <td className='table-data-pro'> <HiCheck /></td>
                                            <td className='table-data-pro'> <HiCheck /></td>
                                            <td className='table-data-pro'> <HiCheck /></td>
                                            <td className='table-data-pro'> <HiCheck /></td>
                                            <td className='table-data-pro'> <HiCheck /></td>
                                            <td className='table-data-pro'> <HiCheck /></td>
                                            <td className='table-data-pro'> <HiCheck /></td>
                                        </tr>
                                        <tr className='tb-border'>
                                            <td className='table-icon-heading'>
                                                <img src={chat_table_gpt} alt="" className='table-img' />
                                                ChatGPT PLUS</td>
                                            <td className='table-text-data'> $20</td>
                                            <td className='table-data'> </td>
                                            <td className='table-data'> <HiCheck /></td>
                                            <td className='table-data'> <HiCheck /></td>
                                            <td className='table-data'> <HiCheck /></td>
                                            <td className='table-data'> <HiCheck /></td>
                                            <td className='table-data'> </td>
                                            <td className='table-data'> </td>
                                        </tr>
                                        <tr className='tb-border'>
                                            <td className='table-icon-heading'>
                                                <img src={Jasper} alt="" className='table-img' />
                                                Jasper AI</td>
                                            <td className='table-text-data'> $49</td>
                                            <td className='table-data'> </td>
                                            <td className='table-data'> <HiCheck /></td>
                                            <td className='table-data'> </td>
                                            <td className='table-data'> <HiCheck /></td>
                                            <td className='table-data'> <HiCheck /></td>
                                            <td className='table-data'> </td>
                                            <td className='table-data'> </td>
                                        </tr>
                                        <tr className='tb-border'>
                                            <td className='table-icon-heading'>
                                                <SiNotion className='table-bulb' />
                                                Notion AI</td>
                                            <td className='table-text-data'> $10</td>
                                            <td className='table-data'> </td>
                                            <td className='table-data'> </td>
                                            <td className='table-data'> </td>
                                            <td className='table-data'> <HiCheck /></td>
                                            <td className='table-data'> <HiCheck /></td>
                                            <td className='table-data'> </td>
                                            <td className='table-data'> <HiCheck /></td>
                                        </tr>
                                        <tr className='tb-border'>
                                            <td className='table-icon-heading'>
                                                <img src={grammarly} alt="" className='table-img' />
                                                Grammarlyx
                                            </td>
                                            <td className='table-text-data'> $12</td>
                                            <td className='table-data'> </td>
                                            <td className='table-data'> </td>
                                            <td className='table-data'> </td>
                                            <td className='table-data'> <HiCheck /></td>
                                            <td className='table-data'> <HiCheck /></td>
                                            <td className='table-data'> </td>
                                            <td className='table-data'> <HiCheck /></td>
                                        </tr>

                                    </tbody>
                                </table>


                                <div>
                                    <button className='table-btn'>Start Free Trial</button>
                                </div>
                            </div>
                        </div>



                        {/* accordion  */}

                        <div className='row'>

                            <div>
                                <h1 className='icons-heading'>Frequently Asked Questions</h1>
                            </div>



                            <div className=''>
                                <Accordion alwaysOpen>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>1. What is Flot?</Accordion.Header>
                                        <Accordion.Body>
                                            Flot is your ultimate AI copilot that seamlessly integrates with all apps and websites on your computer. It's powered by ChatGPT with GPT-4, enabling you to use AI assistance for writing, paraphrasing, summarizing, translating, and more without the need for copy-pasting and switching between apps.                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="1">
                                        <Accordion.Header>2. How is Flot different from other AI tools like Notion AI and Grammarly?</Accordion.Header>
                                        <Accordion.Body>
                                            Flot offers unmatched flexibility and integration. While tools like Notion AI are specialized, Flot can be used universally across apps, browsers, email clients, docs, tools, and more. Additionally, Flot is priced competitively, giving you more features at a lesser cost.                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="2">
                                        <Accordion.Header>3. Can I use Flot for content creation and grammar correction?</Accordion.Header>
                                        <Accordion.Body>
                                            Absolutely! Flot is adept at helping you ideate and craft outlines, ensuring your creative process is smooth. Moreover, Flot functions as a grammar guru, pinpointing errors and refining your content to achieve a polished tone.                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="3">
                                        <Accordion.Header>4. What about multilingual support?</Accordion.Header>
                                        <Accordion.Body>
                                            Flot boasts multilingual capabilities, supporting over 200 languages. You can ask questions in your native language and receive localized answers, making it easier to connect with a global audience.                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="4">
                                        <Accordion.Header>5. Which operating systems does Flot support?</Accordion.Header>
                                        <Accordion.Body>
                                            Flot is available for both Windows and macOS, ensuring a wide range of users can benefit from its features.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="5">
                                        <Accordion.Header>6. Is Flot suitable for developers?</Accordion.Header>
                                        <Accordion.Body>
                                            Yes, Flot works perfectly with development tools, office apps, social media platforms, and browsers. Whether you're drafting an email, coding, or composing a social media post, Flot is there to assist.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="6">
                                        <Accordion.Header>7. How can I get started with Flot?</Accordion.Header>
                                        <Accordion.Body>
                                            You can start with our free trial by visiting our website. Once you're convinced, you can subscribe to our monthly plan and enjoy the extensive features of Flot.                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="7">
                                        <Accordion.Header>8. I have more questions. How can I reach out?</Accordion.Header>
                                        <Accordion.Body>
                                            We're always here to assist! Feel free to contact us via email or Discord, and our team will be delighted to help with any further inquiries.                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>

                            </div>
                        </div>



                        <div className='question-card-div'>
                            <div className='question-card'>
                                <p className='qustioncard-title'>Still have questions?</p>
                                <p className='qustioncard-dis'>We're here to assist you with any further questions! Please don't hesitate to contact us, and we'll be delighted to help.</p>
                                <button className='question-card-button' > <Link to={"mailto:abc@gmail.com"} className='mail-link'><HiOutlineMail className='qustion-card-mail-icon' />Contact us</Link></button>
                            </div>
                        </div>






                      

                        {/* figma card */}


                        {/* <div className=' figma-card'>

                            <div className='row'>
                                <div className='col-xl-6 col-lg-6 px-xl-4 px-lg-3 px-md-3 px-2  '>
                                    <div className='fg-flex-div'>
                                        <div>
                                            <img src={fgi_1} alt="" className='fgi-1' />
                                        </div>
                                        <div>
                                            <p className='fg_head'>Integrations</p>
                                            <p className='fg_dis'>
                                                Level up your marketing using Jasper's integrations to create on-brand Al-assisted content in the tools you use the most
                                            </p>
                                        </div>
                                    </div>

                                    <div className='fg-flex-div'>
                                        <div>
                                            <img src={fgi_2} alt="" className='fgi-1' />
                                        </div>
                                        <div>
                                            <p className='fg_head'>Jasper API</p>
                                            <p className='fg_dis'>
                                                Bring the power of generative Al directly into your own platform with our API.
                                            </p>
                                        </div>
                                    </div>


                                    <div className='fg-flex-div'>
                                        <div>
                                            <img src={fgi_3} alt="" className='fgi-1' />
                                        </div>
                                        <div>
                                            <p className='fg_head'>Browser Extension</p>
                                            <p className='fg_dis'>
                                                Add the Chrome extension to bring your Al assistant everywhere you work.
                                            </p>
                                        </div>
                                    </div>


                                    <div className='fg-flex-div'>
                                        <div>
                                            <img src={fgi_4} alt="" className='fgi-1' />
                                        </div>
                                        <div>
                                            <p className='fg_head'>Business Features</p>
                                            <p className='fg_dis'>
                                                Explore how companies who need a secure, scalable, and customizable Al platform to meets technical needs and align the organization around your brand voice.
                                            </p>
                                        </div>
                                    </div>

                                    <div className='fg-flex-div mb-xl-0 mb-lg-0'>
                                        <div>
                                            <img src={fgi_5} alt="" className='fgi-1' />
                                        </div>
                                        <div>
                                            <p className='fg_head'>Language Translation</p>
                                            <p className='fg_dis'>
                                                Easily create and repurpose content in over 30 languages.
                                            </p>
                                        </div>
                                    </div>


                                </div>
                                <div className='col-xl-6 col-lg-6 px-xl-4 px-lg-3 px-md-3 px-2'>


                                    <div className='fg-flex-div'>
                                        <div>
                                            <img src={fgi_6} alt="" className='fgi-1' />
                                        </div>
                                        <div>
                                            <p className='fg_head'>Campaigns</p>
                                            <p className='fg_dis'>
                                                Seamlessly create end to end marketing campaigns with one brief
                                            </p>
                                        </div>
                                    </div>

                                    <div className='fg-flex-div'>
                                        <div>
                                            <img src={fgi_7} alt="" className='fgi-1' />
                                        </div>
                                        <div>
                                            <p className='fg_head'>Brand Voice & Knowledge</p>
                                            <p className='fg_dis'>
                                                Teach Al about your company facts, product catalogs, audiences, and style guide so your message is always on-brand.
                                            </p>
                                        </div>
                                    </div>


                                    <div className='fg-flex-div'>
                                        <div>
                                            <img src={fgi_8} alt="" className='fgi-1' />
                                        </div>
                                        <div>
                                            <p className='fg_head'>Chat</p>
                                            <p className='fg_dis'>
                                                Have a human-like conversation with a chatbot to assist you with tasks, do research quickly, and answer questions.
                                            </p>
                                        </div>
                                    </div>


                                    <div className='fg-flex-div'>
                                        <div>
                                            <img src={fgi_9} alt="" className='fgi-1' />
                                        </div>
                                        <div>
                                            <p className='fg_head'>Art</p>
                                            <p className='fg_dis'>
                                                Use Al to generate amazing art and images in seconds for ads, thumbnails, illustrations, and more.
                                            </p>
                                        </div>
                                    </div>

                                    <div className='fg-flex-div mb-0'>
                                        <div>
                                            <img src={fgi_10} alt="" className='fgi-1' />
                                        </div>
                                        <div>
                                            <p className='fg_head'>Template Library</p>
                                            <p className='fg_dis'>
                                                Al trained in over 50 specific skills from writing high-converting headlines to SEO optimized blog posts in just a few clicks.
                                            </p>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div> */}

                    </div>
                </div>
            </div>



        </>
    )
}

export default Home

