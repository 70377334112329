import React, { Suspense, useEffect } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'

import Header from './Global/Header/Header'

import Home from "./View/Home/Home"
import Footer from './Global/Footer/Footer'
import Download from './View/Download/Download'
import Pricing from './View/Pricing/Pricing'
import Contact from './View/Contact/Contact'
import Login from './View/Login/Login'
import Signup from './View/Signup/Signup'
import Maybeshow from './View/Maybeshow/Maybeshow'


function App() {

  let location = useLocation()

  useEffect(() => {
    window.scroll(0, 0)
  }, [location])



  return (
    <>

      <Suspense fallback={<>Loading.............</>}>

        <Maybeshow >
          <Header />
        </Maybeshow>

        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/download' element={<Download />} />
          <Route path='/pricing' element={<Pricing />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/login' element={<Login />} />
          <Route path='/signup' element={<Signup />} />

        </Routes>

        <Maybeshow >
          <Footer />
        </Maybeshow>

      </Suspense>


    </>
  )
}

export default App
